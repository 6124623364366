import bgTiled from '@img/stressed-bg-tiled.jpg';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: row;
  place-items: center;
  margin: 16px 0;
`;

const Title = styled.span`
  display: block;
  ${theme.fonts.brandon.medium};
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin-right: 16px;
  text-transform: uppercase;
`;

const Button = styled.button`
  display: block;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.white.default};
  filter: brightness(1);
  height: 44px;
  margin-right: 16px;
  outline: none;
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  width: 44px;

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }
`;

const ButtonText = styled.span`
  ${srOnly};
`;

const ButtonIcon = styled.span`
  display: flex;
  height: 100%;
  place-content: center;
  place-items: center;
  width: 100%;

  svg {
    display: block;
    height: 24px;
    width: 24px;
  }
`;

export default {
  Button,
  ButtonIcon,
  ButtonText,
  Container,
  Title,
};
