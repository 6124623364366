import RichText from '@connected/RichText';
import SEO from '@connected/SEO';
import ArticleBanner, { thumbnail } from '@presentation/ArticleBanner';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

interface ArticlePageProps {
  data: {
    articlePage: ContentModels.ContentfulArticle;
    articleList: ContentModels.ContentfulArticleList;
    site: {
      siteMetadata: {
        origin: string;
      };
    };
  };
}

const ArticlePage: React.FC<ArticlePageProps> = ({ data }) => {
  return (
    <>
      <SEO data={data.articlePage.metadata} url={data.articlePage.url} />
      <ArticleBanner
        backUrl={data.articleList.url}
        date={new Date(data.articlePage.date ?? '')}
        origin={data.site.siteMetadata.origin}
        shareData={{
          title: data.articlePage.title,
          url: data.articlePage.url,
        }}
        subtitle="News"
        thumbnail={thumbnail(data.articlePage.thumbnail?.[0]?.public_id)}
        title={data.articlePage.title}
      />
      {data.articlePage.body && <RichText data={data.articlePage.body} />}
    </>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticlePageQuery($slug: String) {
    articlePage: contentfulArticle(slug: { eq: $slug }) {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      body {
        ...RichTextFragment
      }
      date
      thumbnail
      title
      url
    }
    articleList: contentfulArticleList {
      url
    }
    site {
      siteMetadata {
        origin
      }
    }
  }
`;
