import React, { useEffect, useState } from 'react';
import SvgIconEmail from '!babel-loader!react-svg-loader!@img/icon-email.svg';
import SvgIconFacebook from '!babel-loader!react-svg-loader!@img/icon-facebook-f.svg';
import SvgIconShare from '!babel-loader!react-svg-loader!@img/icon-share.svg';
import s from './ShareIcons.styles';

export interface ShareIconsProps {
  origin: string;
  shareData: ShareData;
}

const ShareIcons: React.FC<ShareIconsProps> = ({ origin, shareData }) => {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    setCanShare(!!navigator.share);
  }, []);

  return (
    <s.Container>
      <s.Title>Share</s.Title>
      {canShare && (
        <s.Button
          onClick={(event) => {
            event.preventDefault();
            share(shareData);
          }}
        >
          <s.ButtonText>Share</s.ButtonText>
          <s.ButtonIcon aria-hidden>
            <SvgIconShare />
          </s.ButtonIcon>
        </s.Button>
      )}
      {!canShare && (
        <>
          <s.Button
            as="a"
            href={getEmailUrl(shareData, origin)}
            target="_blank"
            title="Share via Email"
          >
            <s.ButtonText>Share via Email</s.ButtonText>
            <s.ButtonIcon aria-hidden>
              <SvgIconEmail />
            </s.ButtonIcon>
          </s.Button>
          <s.Button
            as="a"
            href={getFacebookUrl(shareData, origin)}
            target="_blank"
            title="Share via Facebook"
          >
            <s.ButtonText>Share via Facebook</s.ButtonText>
            <s.ButtonIcon aria-hidden>
              <SvgIconFacebook />
            </s.ButtonIcon>
          </s.Button>
        </>
      )}
    </s.Container>
  );
};

export default ShareIcons;

/**
 * Attempt to share using the Web Share API.
 * @param data The share data
 */
function share(data: ShareData): Promise<void> {
  // Check for Level 2 support.
  if (navigator.share && navigator.canShare && navigator.canShare(data)) {
    return navigator.share(data);
  }

  // Check for Level 1 support.
  if (navigator.share) {
    return navigator.share(data);
  }

  // No support so reject.
  return Promise.reject();
}

function getEmailUrl(data: ShareData, origin: string): string {
  const url = new URL(data.url || '/', origin);
  return `mailto:?subject=${data.title}&body=${url}`;
}

function getFacebookUrl(data: ShareData, origin: string): string {
  const url = new URL(data.url || '/', origin);
  return `https://www.facebook.com/sharer/sharer.php?u=${url.href}`;
}

function getTwitterUrl(data: ShareData, origin: string): string {
  const url = new URL(data.url || '/', origin);
  return `https://twitter.com/intent/tweet?text=${data.title}&url=${url.href}`;
}
