import SvgChevronRight from '!babel-loader!react-svg-loader!@img/icon-chevron-right.svg';
import SectionWrapper from '@presentation/SectionWrapper';
import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import TitleContainer from '@presentation/TitleContainer';
import titleContainerStyles from '@presentation/TitleContainer/TitleContainer.styles';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

const ClipPath = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const StyledSectionWrapper = styled(SectionWrapper)`
  clip-path: url(#simple-banner-clip-path);
`;

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  padding: 158px 0 30vw;

  @media ${from(Device.MobileLarge)} {
    padding: 158px 0 25vw;
  }

  @media ${from(Device.Tablet)} {
    padding: 158px 0 20vw;
  }

  @media ${from(Device.TabletLarge)} {
    padding: 242px 0 224px;
  }
`;

const StyledSiteWide = styled(SiteWide)`
  display: flex;
  flex-direction: column;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / span 6;
`;

const ImageColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;
  grid-column: -6 / span 4;

  img {
    border-radius: 3px;
  }
`;

const BackLink = styled(GatsbyLink)`
  display: flex;
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: 24px;
  place-items: center;
  text-transform: uppercase;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const BackIcon = styled(SvgChevronRight)`
  display: inline-block;
  margin-right: 8px;
  transform: rotate(180deg);
  width: 8px;
`;

const StyledTitleContainer = styled(TitleContainer)`
  --title-color: ${theme.brand.blue.default};
  --subtitle-color: ${theme.brand.red.default};

  ${titleContainerStyles.Title} {
    font-size: 24px;
    letter-spacing: normal;
    line-height: 32px;

    @media ${from(Device.TabletLarge)} {
      font-size: 40px;
      letter-spacing: normal;
      line-height: 60px;
    }
  }

  ${titleContainerStyles.Subtitle} {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 16px;

    @media ${from(Device.TabletLarge)} {
      font-size: 18px;
      letter-spacing: 0.02em;
      line-height: 20px;
    }
  }
`;

export default {
  BackIcon,
  BackLink,
  ClipPath,
  ContentColumn,
  ImageColumn,
  SectionWrapper: StyledSectionWrapper,
  SiteWide: StyledSiteWide,
  TitleContainer: StyledTitleContainer,
  Wrapper,
};
