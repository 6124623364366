import ShareIcons from '@presentation/ShareIcons';
import { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import s from './ArticleBanner.styles';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface ArticleBannerProps {
  backUrl?: string;
  date?: Date;
  origin?: string;
  shareData?: ShareData;
  subtitle?: string;
  theme?: ComponentThemeTaxonomy;
  thumbnail?: FluidObject;
  title?: string;
}

const ArticleBanner: React.FC<ArticleBannerProps> = ({
  backUrl,
  date,
  origin,
  shareData,
  subtitle,
  thumbnail,
  title,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <>
      <s.ClipPath>
        <clipPath id="simple-banner-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,0 V0.901 C0.012,0.903,0.045,0.906,0.045,0.902 C0.045,0.9,0.09,0.897,0.09,0.894 C0.09,0.902,0.136,0.876,0.136,0.869 H0.136 C0.137,0.869,0.18,0.907,0.18,0.911 C0.18,0.868,0.229,0.892,0.229,0.895 C0.229,0.895,0.274,0.921,0.274,0.907 C0.274,0.91,0.316,0.933,0.316,0.938 C0.316,0.943,0.364,0.979,0.364,0.95 C0.364,0.94,0.376,0.954,0.388,0.968 C0.399,0.982,0.41,0.995,0.41,0.988 C0.41,0.982,0.464,0.942,0.464,0.963 C0.464,0.968,0.507,0.989,0.507,0.972 C0.507,0.956,0.521,0.968,0.533,0.98 C0.542,0.99,0.55,0.999,0.55,0.995 C0.55,0.99,0.594,0.966,0.594,0.975 C0.594,0.965,0.605,0.975,0.616,0.985 C0.627,0.996,0.639,1,0.639,0.994 C0.639,0.992,0.682,0.99,0.682,0.986 C0.682,0.972,0.725,0.977,0.725,0.965 C0.725,0.963,0.733,0.963,0.743,0.963 C0.755,0.964,0.768,0.964,0.768,0.959 C0.768,0.97,0.814,0.969,0.814,0.972 C0.814,0.954,0.857,0.955,0.857,0.966 C0.857,0.971,0.867,0.966,0.877,0.96 C0.889,0.955,0.901,0.949,0.901,0.957 C0.901,0.947,0.941,0.936,0.941,0.935 C0.941,0.932,0.984,0.914,0.984,0.925 C0.984,0.918,0.991,0.912,1,0.908 V0"></path>
        </clipPath>
      </s.ClipPath>
      <s.SectionWrapper ref={refInView} fade={false} fullWidth theme={theme}>
        <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
          <s.SiteWide>
            <s.ContentColumn>
              {backUrl && (
                <s.BackLink to={backUrl}>
                  <s.BackIcon />
                  Back to list
                </s.BackLink>
              )}
              <s.TitleContainer align="left" title={title} subtitle={getSubtitle(subtitle, date)} />
              {origin && shareData && <ShareIcons origin={origin} shareData={shareData} />}
            </s.ContentColumn>
            {thumbnail && (
              <s.ImageColumn initial="hidden" variants={imageVariants}>
                <GatsbyImage fluid={thumbnail} />
              </s.ImageColumn>
            )}
          </s.SiteWide>
        </s.Wrapper>
      </s.SectionWrapper>
    </>
  );
};

export default ArticleBanner;

function getSubtitle(subtitle?: string, date?: Date): string | undefined {
  if (!subtitle && !date) {
    return undefined;
  }

  let output = '';

  if (subtitle) {
    output += subtitle;

    if (date) {
      output += ' – ';
    }
  }

  if (date) {
    output += formatDate(date);
  }

  return output;
}

function formatDate(value: Date): string {
  const formatter = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return formatter.format(value);
}

export function thumbnail(publicId?: string): FluidObject {
  return {
    aspectRatio: 524 / 337,
    sizes: '(max-width: 1024px) 100vw, 30vw',
    src: imageUrl(publicId, 'c_thumb,w_1024,ar_524:344'),
    srcSet: `
      ${imageUrl(publicId, 'c_thumb,w_512,ar_524:344')} 512w,
      ${imageUrl(publicId, 'c_thumb,w_768,ar_524:344')} 768w,
      ${imageUrl(publicId, 'c_thumb,w_1024,ar_524:344')} 1024w,
      ${imageUrl(publicId, 'c_thumb,w_1536,ar_524:344')} 1536w,
      ${imageUrl(publicId, 'c_thumb,w_2048,ar_524:344')} 2048w
    `,
  };
}
